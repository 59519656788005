import { useEffect, useRef, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import {
  PatientDetails,
  SendVerificationCodeForm,
  Session,
  Step,
} from "./models";
import {
  METHOD,
  CallApi,
  timeout,
  FormatName,
  MapDeepObjectToQueryString,
  sendGAEvent,
} from "./helpers";
import {
  Headings,
  InitialSession,
  InitialPrescription,
  EXISTING_PATIENT,
  NEW_PATIENT,
  InitialConfirmForm,
  REPEAT_CHECKIN_TYPE,
  NEW_CHECKIN_TYPE,
} from "./constants";
import RegistrationType from "./components/RegistrationType";
import ValidatePatient from "./components/ValidatePatient";
import isEmpty from "lodash/isEmpty";
import SendVerificationCode from "./components/SendVerificationCode";
import EnterVerificationCode from "./components/EnterVerificationCode";
import EmergencyNextOfKin from "./components/EmergencyNextOfKin";
import MedicareDetails from "./components/MedicareDetails";
import PrescriptionDetails from "./components/PrescriptionDetails";
import Confirm from "./components/Confirm";
import Payment from "./components/Payment";
import ThankYou from "./components/ThankYou";
import PaymentFailedModal from "./components/PaymentFailedModal";
import CancelModal from "./components/CancelModal";
import SelectPrescriptionType from "./components/SelectPrescriptionType";
import EmergencyPrompt from "./components/EmergencyPrompt";
import startCase from "lodash/startCase";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function App() {
  const [activeStep, setActiveStep] = useState(-1);
  const [heading, setHeading] = useState("Prescriptions");
  const [completedStepPercent, setCompletedStepPercent] = useState(8.5);
  const [remainingStepPercent, setRemainingStepPercent] = useState(91.5);
  const [session, setSession] = useState<Session>(InitialSession);
  const [alert, setAlert] = useState("");
  const [prescription, setPrescription] = useState(InitialPrescription);
  const [enablePrevButton, setEnablePrevButton] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails
  );
  const [confirmForm, setConfirmForm] = useState(InitialConfirmForm);
  const [displayPaymentFailedModal, setDisplayPaymentFailedModal] =
    useState(false);
  const [displayCancelModal, setDisplayCancelModal] = useState(false);

  const selectedPatientType = useRef(0);

  useEffect(() => {
    toggleErrorMessage(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const toggleErrorMessage = (error: boolean): void => {
    setAlert(error ? "There has been a problem. Please try again later." : "");
  };
  
  const initialise = async (cid: number) => {
    try {
      const response = await CallApi(
        "spa_init",
        METHOD.GET,
        null,
        {
          cid,
          type: "scripts",
        },
        setIsLoading,
        setIsError
      );
      if (isError) {
        toggleErrorMessage(true);
        return;
      }
      setSession({
        ...session,
        cid: cid,
        repeatPrice: Number(process.env.REACT_APP_REPEAT_PRESCRIPTION_PRICE),
        newPrice: Number(process.env.REACT_APP_NEW_PRESCRIPTION_PRICE),
        waitText: response.wait_text,
        feeText: response.fee_text,
      });
      setAlert(response.checkin_message);
    } catch (error: any) {
      toggleErrorMessage(true);
    } finally {
      setTimeout(() => {
        nextStep(Step.Select);
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let cid = Number(process.env.REACT_APP_CID);
    if (params.has("cid")) {
      cid = Number(params.get("cid"));
      setSession({
        ...session,
        cid: cid,
      });
    }
    initialise(cid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectPrescriptionType = (type: string) => {
    if (session.checkinMessage.length) {
      return false;
    }

    setPrescription({
      ...prescription,
      price: type === "new" ? session.newPrice : session.repeatPrice,
      version: type === "new" ? "New prescription" : "Repeat prescription",
      repeat: type === "repeat",
      emergency: "No",
    });
    nextStep(Step.Emergency);
  };

  const selectEmergency = (emergency: string) => {
    nextStep(Step.RegType);
  };

  const selectRegistrationType = (patientType: number) => {
    selectedPatientType.current = patientType;
    setSession({
      ...session,
      patientType: patientType,
    });
    nextStep(Step.Patient);
  };

  const validatePatientNextClick = async (response: any) => {
    if (response) {
      setPatientDetails(response as PatientDetails);
      if (
        selectedPatientType.current === NEW_PATIENT &&
        !isEmpty(response.prn)
      ) {
        selectedPatientType.current = EXISTING_PATIENT;
        setSession({
          ...session,
          patientType: EXISTING_PATIENT,
        });
      }
    }
    nextStep(Step.SendCode);
  };

  const restartAsNewPatient = (validatePatientForm: PatientDetails) => {
    setPatientDetails({ ...validatePatientForm });
    setSession({
      ...session,
      patientType: NEW_PATIENT,
    });
    selectedPatientType.current = NEW_PATIENT;
    setActiveHeading(Step.Patient);
  };

  const sendVerificationCodeNextClick = (
    sendVerificationCodeForm: SendVerificationCodeForm
  ) => {
    try {
      setPatientDetails({
        ...patientDetails,
        mobileNo: sendVerificationCodeForm.mobileNo,
        email: sendVerificationCodeForm.email,
        maskedMobile: sendVerificationCodeForm.maskedMobile,
        maskedEmail: sendVerificationCodeForm.maskedEmail,
      });
      setSession({
        ...session,
        verificationCode: sendVerificationCodeForm.verificationCode,
        vopt: sendVerificationCodeForm.vopt,
      });
      nextStep(Step.VerifyCode);
    } catch (e) {
      toggleErrorMessage(true);
    }
  };

  const enterVerificationNextClick = (success: boolean) => {
    if (success) {
      setSession({
        ...session,
        authorised: true,
      });
      if (session.patientType === NEW_PATIENT) {
        nextStep(Step.Medicare);
      } else {
        nextStep(Step.Prescription);
      }
    }
  };

  const medicareDetailsNextClick = async (patientDetails?: PatientDetails) => {
    if (patientDetails) {
      setPatientDetails(patientDetails);
    }

    if (session.patientType === NEW_PATIENT) {
      nextStep(Step.NextOfKin);
    } else {
      nextStep(Step.Prescription);
    }
  };

  const emergencyNextOfKinNextClick = async (
    patientDetails?: PatientDetails
  ) => {
    if (patientDetails) {
      setPatientDetails(patientDetails);
    }
    nextStep(Step.Prescription);
  };

  const prescriptionDetailsNextClick = () => {
    const visitNotes =
      `${prescription.version} details below:\r\n` +
      (!isEmpty(patientDetails.IHI)
        ? `Patient IHI: ${patientDetails.IHI}\r\n`
        : ``) +
      `Require antibiotics: No\r\n` +
      `Require weightloss: No\r\n` +
      `Medication required: ${prescription.medication}\r\n` +
      `Condition: ${prescription.condition}\r\n` +
      `Taking other medication: ${startCase(
        prescription.takingOtherMedications
      )}\r\n` +
      (prescription.takingOtherMedications === "yes"
        ? `Currently taking medications: ${prescription.otherMedications}\r\n`
        : "") +
      `Allergic to medications: ${startCase(prescription.allergic)}\r\n` +
      (prescription.allergic === "yes"
        ? `Allergic medications: ${prescription.allergicMedications}\r\n`
        : "") +
      `Will discuss side-effects or interactions and has read CMI / PI: Yes`;

    setPrescription({
      ...prescription,
      visitNotes,
    });

    setConfirmForm({
      name: FormatName(patientDetails),
      mobileNo: patientDetails.mobileNo,
      email: patientDetails.email,
      prescriptionType: `Repeat/Regular prescription ($${session.repeatPrice})`,
      price: session.repeatPrice,
      agreeTCs: false,
      acknowledge: false,
    });
    nextStep(Step.Confirm);
  };

  const confirmNextClick = () => {
    nextStep(Step.Payment);
  };

  const paymentResult = async (success: boolean) => {
    if (success) {
      await confirmCheckin();
      nextStep(Step.ThankYou);
    } else {
      setDisplayPaymentFailedModal(true);
    }
  };
  const confirmCheckin = async () => {
    // note: the session data is (still) called "sessinfo" in the back end
    const sessinfo = MapDeepObjectToQueryString({
      sessinfo: {
        cid: session.cid,
        display_name: FormatName(patientDetails),
        ctype: prescription.repeat ? REPEAT_CHECKIN_TYPE : NEW_CHECKIN_TYPE,
        pinfo: {
          ...patientDetails,
        },
        IHI: patientDetails.IHI,
        contact_number: patientDetails.mobileNo,
        visit_notes: prescription.visitNotes,
        consult_label: prescription.repeat
          ? "Repeat prescription"
          : "New prescription",
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await CallApi(
      "confirm_checkin",
      METHOD.POST,
      sessinfo,
      null,
      setIsLoading,
      setIsError
    );
  };

  const closePaymentFailedModal = () => {
    setDisplayPaymentFailedModal(false);
    nextStep(Step.Confirm);
  };

  const closeCancelModal = async (event: Event) => {
    setDisplayCancelModal(false);
    if (event.type === "cancel") {
      sendGAEvent(
        "cancel_consult_prescriptions",
        activeStep + 1,
        patientDetails.maskedEmail,
        "Cancel"
      );
      const result = await cancelCheckin();
      if (result) {
        endSession();
      }
    }
    return;
  };

  const cancelCheckin = async () => {
    try {
      if (!patientDetails.prn) {
        return new Promise(function (resolve, reject) {
          resolve({ result: true });
        });
      }
      const body = MapDeepObjectToQueryString({
        sessinfo: {
          cid: session.cid,
          pinfo: { prn: patientDetails.prn },
        },
      });
      await CallApi(
        "cancel_checkin",
        METHOD.POST,
        body,
        null,
        setIsLoading,
        setIsError
      );

      if (isError) {
        toggleErrorMessage(true);
        return;
      }
      return true;
    } catch (e) {
      toggleErrorMessage(true);
    }
  };

  const nextStep = (activeStepNum: number) => {
    if (activeStepNum === activeStep) {
      return;
    }
    setIsError(false);
    if (session.checkinMessage) {
      toggleErrorMessage(false);
    }
    calculateActiveStep(activeStepNum);
    setActiveStep(activeStepNum);
    setActiveHeading(activeStepNum);
  };

  const calculateActiveStep = async (activeStepNum: number) => {
    const newPercent = (activeStepNum + 1) * (100 / 12);
    const start = completedStepPercent;
    const end = newPercent;
    const step = start < end ? 1 : -1;

    for (let i = start; start < end ? i <= end : i >= end; i += step) {
      await timeout(10);
      setCompletedStepPercent(i);
      setRemainingStepPercent(100 - i);
    }
  };

  const setActiveHeading = (panel: Step) => {
    if (
      panel === Step.Patient &&
      selectedPatientType.current === EXISTING_PATIENT
    ) {
      setHeading("Existing patient");
    } else {
      setHeading(Headings[panel]);
    }

    if (panel === Step.Select) {
      setEnablePrevButton(false);
    } else {
      setEnablePrevButton(true);
    }
  };

  const prevButtonClick = () => {
    switch (activeStep) {
      case Step.Prescription:
      case Step.ThankYou:
        endSession();
        nextStep(Step.Select);
        break;
      default:
        nextStep(activeStep - 1);
        break;
    }
  };

  const closeButtonClick = () => {
    setDisplayCancelModal(true);
  };

  const endSession = () => {
    setPatientDetails({} as PatientDetails);
    selectedPatientType.current = 0;
    setPrescription(InitialPrescription);
    setConfirmForm(InitialConfirmForm);
    nextStep(Step.Select);
  };

  return (
    <>
      <div className="container">
        <Header
          heading={heading}
          completedStepPercent={completedStepPercent}
          remainingStepPercent={remainingStepPercent}
          enablePrevButton={enablePrevButton}
          displayCloseButton={activeStep !== Step.Select}
          prevButtonClick={prevButtonClick}
          closeButtonClick={closeButtonClick}
        />
        {(activeStep === Step.Select || alert) && (
          <ProgressBar errorText={alert} waitText={session.waitText} />
        )}

        <div
          className="form-container"
          style={{
            marginTop:
              activeStep === Step.Select || session.checkinMessage
                ? `${0}px`
                : `${90}px`,
          }}
        >
          <form className="cs-chkin-form" autoComplete="off">
            {activeStep === Step.Select && (
              <SelectPrescriptionType
                session={session}
                onChange={selectPrescriptionType}
              />
            )}

            {activeStep === Step.Emergency && (
              <EmergencyPrompt onChange={selectEmergency} />
            )}

            {activeStep === Step.RegType && (
              <RegistrationType onChange={selectRegistrationType} />
            )}

            {activeStep === Step.Patient && (
              <ValidatePatient
                newPatient={session.patientType === NEW_PATIENT}
                patient={patientDetails}
                session={session}
                setIsError={setIsError}
                nextClick={validatePatientNextClick}
                restartAsNewPatientClick={restartAsNewPatient}
              />
            )}

            {activeStep === Step.SendCode && (
              <SendVerificationCode
                session={session}
                patient={patientDetails}
                setIsError={setIsError}
                nextClick={sendVerificationCodeNextClick}
              />
            )}

            {activeStep === Step.VerifyCode && (
              <EnterVerificationCode
                session={session}
                patient={patientDetails}
                setIsError={setIsError}
                nextClick={enterVerificationNextClick}
              />
            )}

            {activeStep === Step.Medicare && (
              <MedicareDetails
                session={session}
                patient={patientDetails}
                setIsError={setIsError}
                handleNextClick={medicareDetailsNextClick}
              />
            )}

            {activeStep === Step.NextOfKin && (
              <EmergencyNextOfKin
                session={session}
                patient={patientDetails}
                setIsError={setIsError}
                handleNextClick={emergencyNextOfKinNextClick}
              />
            )}

            {activeStep === Step.Prescription && (
              <PrescriptionDetails
                patient={patientDetails}
                prescription={prescription}
                setPrescription={setPrescription}
                handleNextClick={prescriptionDetailsNextClick}
              />
            )}

            {activeStep === Step.Confirm && (
              <Confirm
                patient={patientDetails}
                prescription={prescription}
                confirmForm={confirmForm}
                setConfirmForm={setConfirmForm}
                handleNextClick={confirmNextClick}
              />
            )}

            {activeStep === Step.Payment && (
              <Payment
                session={session}
                prescription={prescription}
                patient={patientDetails}
                setIsError={setIsError}
                feeText={session.feeText}
                paymentResult={paymentResult}
              />
            )}

            {activeStep === Step.ThankYou && (
              <ThankYou closeClick={endSession} />
            )}
          </form>
        </div>
      </div>

      <PaymentFailedModal
        open={displayPaymentFailedModal}
        handleClose={() => closePaymentFailedModal()}
      />

      <CancelModal
        open={displayCancelModal}
        handleClose={(e) => closeCancelModal(e as Event)}
      />
    </>
  );
}

export default App;
