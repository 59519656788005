import { isEmpty } from "lodash";

export enum METHOD {
  GET = "GET",
  POST = "POST",
}
export const BuildUrl = (url: string) => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  return `${REACT_APP_API_URL ? REACT_APP_API_URL : ""}/${url}`;
};

export const MapObjectToFormData = (obj: Object): FormData => {
  const formData = new FormData();
  Object.entries(obj).forEach((props) => {
    if (props[1]) {
      formData.append(props[0], props[1].toString());
    }
  });
  return formData;
};

export const MapObjectToQueryString = (obj: Object): string => {
  let queryString = "";
  if (obj) {
    queryString = Object.entries(obj)
      .filter((param) => param[1])
      .reduce((acc, param, index) => {
        const thisParam = `${param[0]}=${param[1]}`;
        if (index === 0) {
          return acc + thisParam;
        } else {
          return `${acc}&${thisParam}`;
        }
      }, "");
  }
  return encodeURI(queryString);
};

export const MapDeepObjectToQueryString = (
  obj: Object,
  keyPrefix: string = ""
): string => {
  let queryString = "";
  if (obj) {
    queryString = Object.entries(obj)
      .filter((param) => param[1])
      .reduce((acc, param, index) => {
        let thisParam = "";
        if (
          typeof param[1] === "object" &&
          Object.entries(param[1]).length > 0
        ) {
          let newKeyPrefix = "";
          if (!isEmpty(keyPrefix)) {
            newKeyPrefix = keyPrefix + encodeURI(`[${param[0]}]`);
          } else {
            newKeyPrefix = encodeURI(param[0]);
          }
          thisParam = MapDeepObjectToQueryString(param[1], newKeyPrefix);
        } else {
          thisParam = encodeURI(`${param[0]}=${param[1]}`);
          if (!isEmpty(keyPrefix)) {
            thisParam = `${keyPrefix}${encodeURI(
              "[" + param[0] + "]=" + param[1]
            )}`;
          } else {
            thisParam = encodeURI(`${param[0]}=${param[1]}`);
          }
        }

        if (index === 0) {
          return acc + thisParam;
        } else {
          return `${acc}&${thisParam}`;
        }
      }, "");
  }
  return queryString;
};

export const CallApi = async (
  path: string,
  method: METHOD,
  body: any,
  params: any,
  setIsLoading: Function,
  setIsError: Function
) => {
  try {
    setIsLoading(true);
    setIsError(false);
    let queryString = null;
    if (params) {
      queryString = MapObjectToQueryString(params);
    }
    const url = BuildUrl(path) + `${queryString ? "?" + queryString : ""}`;
    const headers = new Headers({
      "X-Requested-With": "XMLHttpRequest",
    });
    if (
      ["verify_patient", "confirm_checkin", "cancel_checkin"].includes(path)
    ) {
      headers.append("Content-Type", "application/x-www-form-urlencoded");
    }
    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    if (!response.ok) {
      setIsError(true);
      return;
    }
    return await response.json();
  } catch (error: any) {
    setIsError(true);
    throw error;
  } finally {
    setIsLoading(false);
  }
};

export const ValidatePostcode = async (
  postcode: string,
  setIsLoading: Function
) => {
  try {
    setIsLoading(true);
    const url = BuildUrl(`validate_postcode/${postcode}`);
    const headers = new Headers({
      "X-Requested-With": "XMLHttpRequest",
    });
    const response = await fetch(url, { method: "GET", headers });
    if (response.status === 204) {
      return true;
    }
    return false;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  } finally {
    setIsLoading(false);
  }
};
