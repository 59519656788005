import React, { useCallback, useEffect, useState } from "react";
import { Button, InputControl, RadioButtonControl } from "./Common";
import { PatientDetails, SendVerificationCodeForm, Session } from "../models";
import isEmpty from "lodash/isEmpty";
import {
  CallApi,
  isValidEmail,
  isValidMobileNo,
  maskEmail,
  maskMobile,
  METHOD,
  sendGAEvent,
} from "../helpers";
import {
  EXISTING_PATIENT,
  InitialSendVerificationCodeForm,
  NEW_PATIENT,
} from "../constants";

interface Props {
  session: Session;
  patient: PatientDetails;
  setIsError: Function;
  nextClick: Function;
}

const SendVerificationCode = React.memo(
  ({ session, patient, setIsError, nextClick }: Props) => {
    const [sendVerificationCodeForm, setSendVerificationCodeForm] =
      useState<SendVerificationCodeForm>(InitialSendVerificationCodeForm);
    const [isLoading, setIsLoading] = useState(false);
    const [displayEmail, setDisplayEmail] = useState(false);
    const [readonly, setReadonly] = useState(true);
    const [focusName, setFocusName] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isButtonActive, setIsButtonActive] = useState(false);

    const vOptions = [
      {
        id: "voptm",
        value: "M",
        label: "Mobile",
      },
      {
        id: "vopte",
        value: "E",
        label: "Email",
      },
    ];

    const handleChange = (e: any): void => {
      const { name, value } = e.target;
      let newVerficationDetails = {
        ...sendVerificationCodeForm,
        [name]: value,
      };
      setSendVerificationCodeForm(newVerficationDetails);
      updateButtonIsActive(newVerficationDetails);
      setFocusName("");
    };

    const validMobileNo = (
      sendVerificationCodeForm: SendVerificationCodeForm
    ): boolean => {
      return isValidMobileNo(sendVerificationCodeForm.mobileNo);
    };
    const validEmail = useCallback(
      (sendVerificationCodeForm: SendVerificationCodeForm): boolean => {
        return (
          session.patientType === EXISTING_PATIENT ||
          isValidEmail(sendVerificationCodeForm.email)
        );
      },
      [session.patientType]
    );

    const validOption = useCallback(
      (sendVerificationCodeForm: SendVerificationCodeForm): boolean => {
        return (
          session.patientType === EXISTING_PATIENT ||
          ["M", "E"].includes(sendVerificationCodeForm.vopt)
        );
      },
      [session.patientType]
    );

    const updateButtonIsActive = useCallback(
      (sendVerificationCodeForm: SendVerificationCodeForm): void => {
        const isButtonActive =
          validMobileNo(sendVerificationCodeForm) &&
          validEmail(sendVerificationCodeForm) &&
          validOption(sendVerificationCodeForm);
        setIsButtonActive(isButtonActive);
      },
      [validEmail, validOption]
    );

    const validate = () => {
      if (isLoading) {
        return;
      }
      let errorsExist = false;
      setFocusName("");
      setValidationErrors([]);

      const validationErrors: string[] = [];
      const addError = (fieldName: string) => {
        if (!errorsExist) {
          setTimeout(() => {
            setFocusName(fieldName);
          });
        }
        validationErrors.push(fieldName);
        errorsExist = true;
      };

      if (!validMobileNo(sendVerificationCodeForm)) {
        addError("mobileNo");
      }

      if (!validEmail(sendVerificationCodeForm)) {
        addError("email");
      }

      if (!validOption(sendVerificationCodeForm)) {
        addError("vopt");
      }

      if (errorsExist) {
        setTimeout(() => setValidationErrors(validationErrors));
      } else {
        callSendVerificationCode();
      }
    };

    const callSendVerificationCode = async (): Promise<void> => {
      try {
        const formData = new FormData();
        formData.append("pmob", sendVerificationCodeForm.mobileNo);
        formData.append("pemail", sendVerificationCodeForm.email);
        formData.append("vopt", sendVerificationCodeForm.vopt);
        formData.append("cid", session.cid.toString());
        formData.append("code_length", "4");
        formData.append("brand", "scripts");
        const response = await CallApi(
          "code_patient",
          METHOD.POST,
          formData,
          null,
          setIsLoading,
          setIsError
        );

        if (Number(response.stat) === 1) {
          const newVerificationDetails = {
            ...sendVerificationCodeForm,
            maskedMobile: maskMobile(sendVerificationCodeForm.mobileNo),
            maskedEmail: maskEmail(sendVerificationCodeForm.email),
            verificationCode: response.msg,
          };

          setSendVerificationCodeForm(newVerificationDetails);
          sendGAEvent(
            "personal_verification_details",
            5,
            "Personal Verification Details",
            newVerificationDetails.maskedEmail
          );
          nextClick(newVerificationDetails);
        }
      } catch (e) {
        setIsError(true);
      }
    };

    useEffect(() => {
      const verficationDetailsExist = !!patient.mobileNo || !!patient.email;

      let newSendVerificationCodeForm = {} as SendVerificationCodeForm;
      if (verficationDetailsExist) {
        if (session.patientType === NEW_PATIENT) {
          newSendVerificationCodeForm = {
            ...patient,
            vopt: session.vopt,
            verificationCode: session.verificationCode,
            patientExists: true,
          };
        } else if (session.patientType === EXISTING_PATIENT) {
          const maskedMobile = maskMobile(patient.mobileNo);
          const maskedEmail = maskEmail(patient.email);

          newSendVerificationCodeForm = {
            ...patient,
            maskedMobile,
            maskedEmail,
            vopt: session.vopt,
            verificationCode: session.verificationCode,
            patientExists: true,
          };
          setDisplayEmail(!isEmpty(patient.email));
        }

        setSendVerificationCodeForm(newSendVerificationCodeForm);
      }

      if (session.patientType === NEW_PATIENT) {
        setReadonly(false);
        setDisplayEmail(true);
      }

      updateButtonIsActive(
        verficationDetailsExist
          ? newSendVerificationCodeForm
          : sendVerificationCodeForm
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, patient]);

    return (
      <>
        <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
          <div className="col-xs-11">
            <p className="cs-chkin-form-step-heading mb-4">
              Let's make sure it's you
            </p>
            <InputControl
              type="mobile"
              label="Mobile Number*"
              name="mobileNo"
              value={
                session.patientType === EXISTING_PATIENT
                  ? sendVerificationCodeForm.maskedMobile
                  : sendVerificationCodeForm.mobileNo
              }
              maxlength={10}
              disabled={readonly}
              onChange={handleChange}
              focus={focusName === "mobileNo"}
              error={validationErrors.includes("mobileNo")}
            />

            {displayEmail && (
              <>
                <InputControl
                  type="text"
                  label="Email Address*"
                  name="email"
                  value={
                    session.patientType === EXISTING_PATIENT
                      ? sendVerificationCodeForm.maskedEmail
                      : sendVerificationCodeForm.email
                  }
                  disabled={readonly}
                  onChange={handleChange}
                  focus={focusName === "email"}
                  error={validationErrors.includes("email")}
                />

                <RadioButtonControl
                  label="Send verification code to"
                  name="vopt"
                  options={vOptions}
                  onChange={handleChange}
                  value={sendVerificationCodeForm.vopt}
                  error={validationErrors.includes("vopt")}
                />
              </>
            )}

            <div className="form-group">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
                title="Prev"
              ></button>
              <Button
                label="Send code"
                handleClick={validate}
                isLoading={isLoading}
                isActive={isButtonActive}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default SendVerificationCode;
